import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router";
import store from "@/store";
import mitt from "mitt";
import vueEsign from 'vue-esign'

// 引入样式
import '@nutui/nutui/dist/packages/toast/style';
import '@nutui/nutui/dist/packages/dialog/style';

const app = createApp(App)

app.config.globalProperties.Bus = new mitt()

app.use(router)
app.use(store)
app.use(vueEsign)
app.mount('#app')
